<!--
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 -->


<!-- 
    Router-Outlet is an Angular directive to from the `Router` Library that 
    is used to insert the component matched by routes (defined in 
    app-routing.module.ts) to be displayed on the screen. See the Angular
    Router docs (https://angular.io/guide/router) for more info.
-->
<router-outlet></router-outlet>
