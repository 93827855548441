/**
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
*/


/**  If connecting to a live Firebase project (one that you set up in the 
 * Firebase console) put your config vars into the the object in this file.
 * Otherwise, leave untouched to enable connection to demo project and emulators.
 * For more information about generating, finding, or using project config
 * objects, visit https://support.google.com/firebase/answer/7015592.
*/
export const projectConfig = {
    apiKey: "AIzaSyDYmtRUaOBEDsPPlBAStMrTaLoW4yCjguw",
    authDomain: "terasky-baltic.firebaseapp.com",
    projectId: "terasky-baltic",
    storageBucket: "terasky-baltic.appspot.com",
    messagingSenderId: "244059952280",
    appId: "1:244059952280:web:35ed2c2f10c5ead5546ced"
}
