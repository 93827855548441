<!--
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 -->

<div
  class="restaurant-card-container"
  *ngIf="restuarant"
  (click)="onCardClicked()"
>
  <mat-card class="restaurant-card">
    <img
      mat-card-image
      [src]="restuarant.photo"
      alt="Photo of the restuarant"
      id="mat-card-image"
    />

    <mat-card-content class="card-info">
      <div class="info-header">
        <mat-card-title [innerHTML]="restuarant.name">
          Restaurant Name
        </mat-card-title>
        <span class="price">{{ "$".repeat(restuarant.price) }}</span>
      </div>
      <div class="star-icon-row">
        <!-- Draw n filled in stars, where n = restaurant.avgRating -->
        <ng-container *ngFor="let _ of [].constructor(restuarant.avgRating)">
          <i class="template material-icons rating-star">star</i>
        </ng-container>

        <!-- Draw 5-n hollow stars, where n = restaurant.avgRating -->
        <ng-container
          *ngFor="let _ of [].constructor(5 - restuarant.avgRating)"
        >
          <i class="template material-icons rating-star">star_border</i>
        </ng-container>
        <mat-card-subtitle
          >{{ restuarant.category }} ● {{ restuarant.city }}
        </mat-card-subtitle>
      </div>
    </mat-card-content>
  </mat-card>
</div>
