<!--
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 -->

<div class="review-list-container" *ngIf="reviews | async as data">
  <div *ngFor="let review of reviews | async">
    <!-- Begin Individual Review Component -->
    <div class="review-container">
      <div class="row-1">
        <p id="reviewer-name" [innerHTML]="review.userName"></p>
        <div class="star-container">
          <ng-container *ngFor="let _ of [].constructor(review.rating)">
            <i hidden class="template material-icons" id="star-border-icon"
              >star</i
            >
          </ng-container>
          <ng-container *ngFor="let _ of [].constructor(5 - review.rating)">
            <i hidden class="template material-icons" id="star-border-icon"
              >star_border</i
            >
          </ng-container>
        </div>
      </div>
      <div class="row-2">
        <p id="review-text" [innerHTML]="review.text"></p>
      </div>
      <mat-divider></mat-divider>
    </div>
    <!-- End Individual Review Component -->
  </div>
</div>
