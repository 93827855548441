<!--
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 -->

<div *ngIf="restaurantData | async as data">
  <div class="toolbar-wrapper">
    <mat-toolbar
      [ngStyle]="{
      'background': 'linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7)), url(' + data.photo + ')',
      'background-size': 'cover',
      'box-shadow': 'rgba(0, 0, 0, .2) 0px 2px 2px',
    }"
    >
      <mat-toolbar-row id="close-row">
        <a [routerLink]="['/']">
          <mat-icon id="close-icon">close</mat-icon>
        </a>
      </mat-toolbar-row>

      <mat-toolbar-row class="block" id="info-container">
        <div class="max-width-600">
          <h1 [innerHTML]="data.name"></h1>
          <span class="price">{{ "$".repeat(data.price) }}</span>

          <div class="star-icon-row">
            <ng-container *ngFor="let _ of [].constructor(data.avgRating)">
              <i hidden class="template material-icons" id="star-border-icon"
                >star</i
              >
            </ng-container>
            <ng-container *ngFor="let _ of [].constructor(5 - data.avgRating)">
              <i hidden class="template material-icons" id="star-border-icon"
                >star_border</i
              >
            </ng-container>
            <p class="header-category-city">
              {{ data.category }} ● {{ data.city }}
            </p>
          </div>
          <button mat-fab color="accent" (click)="openDialog()">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </mat-toolbar-row>
    </mat-toolbar>
  </div>
  <app-review-list [restaurantID]="data.id"></app-review-list>
</div>
